import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ModelObject } from '@londonhydro/api-core-angular';
import { Address, Customer, PhoneNumber, ServiceRequestQueue, User } from '@londonhydro/utility-model';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError, timer, of } from 'rxjs';
import { catchError, map, tap, retry, retryWhen, concatMap, delay } from 'rxjs/operators';
import * as _ from 'underscore';

import { AbstractService } from '../../../framework/service/abstract.service';
import { environment } from '../../../../environments/environment';
import { Audit } from '../model/audit';

export enum PhoneNumberType {
  NOTIFICATION = 'NOTIFICATION',
  BUSINESS = 'BUSINESS/WORK',
  CELL = 'CELL',
  RESIDENTIAL = 'RESIDENTIAL'
}

@Injectable({
  providedIn: 'root'
})
export class CsrDataService extends AbstractService {

  constructor(@Inject('_CSR_MODULE_CONFIG_') _config: any, private http: HttpClient, private cookieService: CookieService) {
    super(_config);
  }

  getAuditList(userName: string, eventName: string, status: string, startDate: string, endDate: string): Observable<Audit[]> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);

    let apiUrl = '/audits';
    let queryParams = new HttpParams();
    if (userName) {
      apiUrl = `/audits?userName=${encodeURIComponent(userName)}`;
    }
    if (status) {
      queryParams = queryParams.set('status', status);
    }
    if (eventName) {
      queryParams = queryParams.set('eventName', eventName);
    }
    if (startDate) {
      queryParams = queryParams.set('startDate', startDate);
    }
    if (endDate) {
      queryParams = queryParams.set('endDate', endDate);
    }

    return this.http.get(environment.iam.endPoint + apiUrl,
      { headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken }), params: queryParams })
      .pipe(
        map((dataList: Array<any>) => _.map(dataList, (data: any) => _.extend(new Audit(), data))),
        catchError(
          this.handleError('getAuditList', null)
        )
      );
  }

  assignFormRequest(requestData: string[], userId?: string): Observable<ServiceRequestQueue[]> {
    const requestIds = requestData.join(',');
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json; charset=UTF-8'
      })
    };

    const reqBody = { userId, requestIds };
    const apiUrl = `/customerService/request/assign`;
    return this.http.post<ServiceRequestQueue[]>(environment.customerservice.endPoint + apiUrl, reqBody, authHeader).pipe(
      map((dataList: Array<any>) => _.map(dataList, (data: any) => _.extend(new ServiceRequestQueue(), data))),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  getMenuList(): Observable<any> {
    const apiUrl = '../../../../../assets/menuList.json';
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getBrandingList(): Observable<any> {
    const apiUrl = environment.brandingUrl;
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
  getSettingsList(): Observable<any> {
    const apiUrl = '../../../../../settings/settings.json';
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
  getIamEnJsonList(url: string): Observable<any> {
    const apiUrl = url + 'iam_en.json';
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  getCsrUserListMaster(): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const apiUrl = '/users?roles=CSR';
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.get<any>(environment.iam.endPoint + apiUrl, authHeader)
      .pipe(
        tap(res => {
          console.log('fetched User List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  getCSRGroupMembers(groupName: string): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const apiUrl = `/customerService/groupmembers?formGroup=${groupName}`;
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.get<User[]>(environment.customerservice.endPoint + apiUrl, authHeader)
      .pipe(
        map((dataList: Array<any>) => _.map(dataList, (data: any) => _.extend(new User(), data))),
        catchError(error => {
          return throwError(error);
        })
      );
    /* const apiUrl = `/assets/resources/mockdb/groupMembers.json`;
    return this.http.get<any>(apiUrl).pipe(
      tap(res => {
        console.log('fetched My Group List:', res);
      }),
      catchError(error => {
        return throwError(error);
      })
    ); */
  }
  getMyGroup(): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const apiUrl = `/customerService/mygroups`;
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.get<any>(environment.customerservice.endPoint + apiUrl, authHeader).pipe(

   /*  const apiUrl = `/assets/resources/mockdb/myGroups.json`;
    return this.http.get<any>(apiUrl).pipe( */
      tap(res => {
        console.log('fetched My Group List:', res);
      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

  getUserAccount(searchUserKey: any): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.get(environment.iam.endPoint + searchUserKey, authHeader)
      .pipe(
        tap(res => {
          console.log('fetched User List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
  postError(searchKey: string, forceSearch: boolean, syncChild: boolean, msg?: string, retryCount?: number) {
    // if (environment.postErrorEndpoint == null || environment.postErrorEndpoint === '' || environment.postErrorEndpoint === undefined) {
    //  return true;
    // }
    /* else {
      let queryParams = new HttpParams();
      if(msg) {
        queryParams = queryParams.append('msg', msg);
      }
      if(retryCount) {
        queryParams = queryParams.append('retryCount', retryCount);
      }
      queryParams = queryParams.append('customerId', searchKey);
      queryParams = queryParams.append('force', forceSearch);
      queryParams = queryParams.append('syncChild', syncChild);
      const apiUrl = environment.postErrorEndpoint;
      return this.http.get<any>(apiUrl, {params: queryParams})
      .pipe(
        tap(res => {
          console.log('post error:', res);
        }),
        catchError(err => this.handleError('postError', null))
      );
    } */
    if (environment.postErrorEndpoint != null || environment.postErrorEndpoint !== '' || environment.postErrorEndpoint !== undefined) {
      (document.getElementById("hidden_img")as HTMLImageElement).src = environment.postErrorEndpoint + "?msg=" + msg + "&retryCount=" + retryCount + "&customerId=" + searchKey;
    }
}

  getCustomerAvailability(searchKey: string, forceSearch = false, syncChild = false): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    if (environment.accountCheckEndpoint == null || environment.accountCheckEndpoint === '') {
      return of(true);
    }
    const apiUrl = `?customerId=${encodeURIComponent(searchKey)}&force=${forceSearch}`;
    if(syncChild) {
      return this.http.get(environment.accountCheckEndpoint + apiUrl, authHeader)
      .pipe(
        tap(res => {
          console.log('customer data available:', res);
        }),
        catchError(err => this.handleError('getCustomerAvailability', null))
      );
    } else {
      return this.http.get(environment.accountCheckEndpoint + apiUrl, authHeader)
      .pipe(
        tap(res => {
          console.log('customer data available:', res);
        }),
        retryWhen(error =>
          error.pipe(
            concatMap((error, count) => {
              if (count <= 20) {
                return of(error);
              }
              return throwError(error);
            }),
            delay(2000)
          )
        ),
        catchError(err => this.handleError('getCustomerAvailability', null))
      ); //, delay: 6000
    }
  }

  getCustomers(searchKey: string): Observable<Customer[]> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    const apiUrl = `/customers?searchText=${encodeURIComponent(searchKey)}`;
    const _this = this;
    return this.http.get<Customer[]>(environment.accountservice.endPoint + apiUrl, authHeader)
      .pipe(
        map((dataList: Array<any>) => _.map(dataList, (data: any) => {
          const tData: Customer = _.extend(new Customer(), data);
          _this.postCastCustomer(tData);
          return tData;
        })),
        catchError(this.handleError('getCustomers', null))
      );
  }

  private postCastCustomer(v: Customer): void {
    if (v.mailingAddresses) {
      const addresses = _.map(Object.values(v.mailingAddresses), (obj, key) => {
        const addr: Address = _.extend(new Address(), obj);
        addr['toDisplay'] = addr.fullAddress;
        return addr;
      });
      v.mailingAddresses = addresses;
      if (v.mailingAddresses && v.mailingAddresses.length > 0) {
        v.standardMailingAddress = v.mailingAddresses[0];
      }
      console.log('v.mailingAddresses:::', v.mailingAddresses);
    }
    if (v.phoneNumbers) {
      const cPhoneNumberMap = new Map(Object.entries(v.phoneNumbers));
      if (cPhoneNumberMap) {
        v.residentialNumbers = cPhoneNumberMap.get(PhoneNumberType.RESIDENTIAL);
        v.residentialNumbers = _.map(v.residentialNumbers || [], (ph: any) => _.extend(new PhoneNumber(), ph));
        v.businessNumbers = cPhoneNumberMap.get(PhoneNumberType.BUSINESS);
        v.businessNumbers = _.map(v.businessNumbers || [], (ph: any) => _.extend(new PhoneNumber(), ph));
        v.cellNumbers = cPhoneNumberMap.get(PhoneNumberType.CELL);
        v.cellNumbers = _.map(v.cellNumbers || [], (ph: any) => _.extend(new PhoneNumber(), ph));
        v.notificationNumbers = cPhoneNumberMap.get(PhoneNumberType.NOTIFICATION);
        v.notificationNumbers = _.map(v.notificationNumbers || [], (ph: any) => _.extend(new PhoneNumber(), ph));
      }
    }
  }

  /* api calls in customer lookup section - start */
  getCustomerInfobyId(customerId: string): Observable<Customer> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    const apiUrl = `/customers/${customerId}`;
    return this.http.get<Customer>(environment.accountservice.endPoint + apiUrl, authHeader)
      .pipe(
        map(x => ModelObject.assign(x, Customer)),
        catchError(this.handleError('getCustomers', null))
      );
  }

  getUserInfoById(userId: string): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    const apiUrl = `/users/${userId}`;
    return this.http.get<any>(environment.iam.endPoint + apiUrl, authHeader)
      .pipe(
        tap(res => {
          console.log('user info:', res); /* res.status */
        }),
        catchError(this.handleError('Error in sending email:', null))
      );
  }

  getUserDetailByCustomerId(customerId: string): Observable<User[]> {
    return this.getUserDetails(null, customerId);
  }

  getUserDetailByUserName(userName: string): Observable<User[]> {
    return this.getUserDetails(userName);
  }

  getUserDetails(userName?: string, customerId?: string): Observable<User[]> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    const apiUrl = `/users?userName=${userName ? encodeURIComponent(userName) : ''}&customerId=${customerId ? encodeURIComponent(customerId) : ''}`;
    return this.http.get<User[]>(environment.adminservice.endPoint + apiUrl, authHeader)
      .pipe(
        map((dataList: Array<any>) => _.map(dataList, (data: any) => _.extend(new User(), data))),
        catchError(this.handleError('getCustomers', null))
      );
  }

  getUserSearch(searchText?: string): Observable<User[]> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    const apiUrl = `/users?searchText=${searchText ? searchText : ''}`;
    return this.http.get<User[]>(environment.adminservice.endPoint + apiUrl, authHeader)
      .pipe(
        map((dataList: Array<any>) => _.map(dataList, (data: any) => _.extend(new User(), data))),
        catchError(this.handleError('getCustomers', null))
      );
  }

  getUnregisteredUserDetail(customerId: string): Observable<User> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    const apiUrl = `/userAccounts/${encodeURIComponent(customerId + ' (Not Registered Yet)')}`;
    return this.http.get<User>(environment.adminservice.legacyEndPoint + apiUrl, authHeader)
      .pipe(
        map((data: any) => _.extend(new User(), data)),
        catchError(this.handleError('getUnregisteredUserDetail', null))
      );
  }


  sendInvitationEmail(userId: string, form: any): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const apiUrl = `/users/${userId}/register`;
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.post<any>(environment.iam.endPoint + apiUrl, form, authHeader);
  }

  getCSRSearchInfo(searchKey: string): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    const apiUrl = `/customers/search?searchParam=` + encodeURIComponent(searchKey);
    return this.http.get<any>(environment.adminservice.endPoint + apiUrl, authHeader)
      .pipe(
        tap(res => {
          console.log('fetched CSRSearchInfo:', res);
        }),
        catchError(this.handleError('CSRSearchInfo', null))
      );
  }


  /* Api call in cust-lookup email search - start  */
  getCSRUserAccountsInfobyEmail(username: string): Observable<any> {
    const loginApiUrl = '/userAccounts/email/';
    return this.http.get<any>(environment.customerApiEndPoint + loginApiUrl + encodeURIComponent(username))
      .pipe(
        tap(res => {
          console.log('fetched CSR User Accounts info by email:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
  /* Api call in cust-lookup email search - end  */

  /* Api for Customer Search Section */
  getCustomerSearchInfo(customerSearchText: any): Observable<any> {
    const configProp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.get<any>(environment.customerSearchApiEndPoint +
      '/Customer?searchText=' + customerSearchText + '&include=MailingAddress', configProp) /* customerApiEndPoint */
      .pipe(
        tap(res => {
          console.log('getCustomerSearchInfo::', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /* api call for customer registration search */
  getCustomerSearch(customerId: string): Observable<any> {
    const configProp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.get<any>(environment.customerSearchApiEndPoint +
      '/Customer/' + customerId, configProp)
      .pipe(
        tap(res => {
          console.log('getCustomerSearch::', res);
        }),
        catchError(this.handleError('getCustomerSearch:', null))
      );
  }

  findCustomerUnitAndTenantCounts(customerId: string): Observable<any> {
    const configProp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.get<any>(environment.customerSearchApiEndPoint +
      '/Customer/' + customerId + '/unitAndTenantCounts', configProp)
      .pipe(
        tap(res => {
          console.log('findCustomerUnitAndTenantCounts::', res);
        }),
        catchError(this.handleError('findCustomerUnitAndTenantCounts:', null))
      );
  }

  /* Post API call for Edit my profile */
  updateMyProfile(id: any, params: any): Observable<any> {
    const url = '/user/' + id;
    return this.http.post(environment.iam.endPoint + url, params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      }),
      observe: 'response'
    })
      .pipe(
        tap(res => {
          console.log('updateMyProfile::', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /* Post API call for change password in Edit my profile */
  changePassword(id: any, params: any): Observable<any> {
    const changePasswordUrl = '/user/' + id + '/changePassword';
    return this.http.post(environment.iam.endPoint + changePasswordUrl, params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
      }),
      observe: 'response'
    })
      .pipe(
        tap(res => {
          console.log('updateMyProfile::', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /* api calls in registration check section - start */
  getRegCheckbyId(searchRegCheckKey: string): Observable<Array<any>> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const regCheckUrl = '/admin/customerRegistrationData?id=';
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken, 'Content-Type': 'application/json' })
    };
    return this.http.get<any>(environment.adminservice.endPoint + regCheckUrl + searchRegCheckKey, authHeader)
      .pipe(
        tap(res => {
          console.log('fetched registration check data:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
  /* api calls in registration check section - end */

  getCISUpdates(processed?: boolean, customerId?: string, accountId?: string,
    startDate?: string, endDate?: string): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    let apiUrl = '';
    if (processed === true) {
      apiUrl = '/queue/serverProcessedall';
    } else {
      apiUrl = '/queue/serverall';
    }
    let queryParams = new HttpParams();
    if (customerId) {
      queryParams = queryParams.set('customerId', customerId);
    }
    if (accountId) {
      queryParams = queryParams.set('accountId', accountId);
    }
    if (startDate) {
      queryParams = queryParams.set('startDate', startDate);
    }
    if (endDate) {
      queryParams = queryParams.set('endDate', endDate);
    }
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken }), params: queryParams
    };
    return this.http.get(environment.adminservice.legacyEndPoint + apiUrl, authHeader)
      .pipe(
        tap(res => {
          console.log('getUserAccount::', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getPayementNotifications(): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const apiUrl = '/queue/paymentNotificationsAll';
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.get(environment.adminservice.endPoint + apiUrl, authHeader)
      .pipe(
        tap(res => {
          console.log('getUserAccount::', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  linkCustomer(customerId: string, reqBody: any): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const apiUrl = `/customers/${customerId}/linkCustomer`;
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.post<any>(environment.accountservice.endPoint + apiUrl, reqBody, authHeader);
  }

  unlinkCustomer(customerId: string, reqBody: any): Observable<any> {
    const accessToken = this.cookieService.get(environment.iam.sessionTokenKey);
    const apiUrl = `/customers/${customerId}/unlinkCustomer`;
    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };
    return this.http.post<any>(environment.accountservice.endPoint + apiUrl, reqBody, authHeader);
  }
}
